<template>
  <table 
    class="n-grid"
    :class="{
      'no-v-border': !field.properties.basic.hasBorder.vertical,
      'no-h-border': !field.properties.basic.hasBorder.horizontal,
      'no-border': !field.properties.basic.hasBorder.all,
    }"
  >
    <tr class="column-row">
      <th
        v-for="(_, colIndex) in field.properties.basic.columns.limit"
        :key="'grid-column' + colIndex"
        class="grid-column"
        :style="{
          width: field.properties.basic.columns.width[colIndex] ? `${field.properties.basic.columns.width[colIndex]}%` : 'auto' 
        }"
      />
    </tr>
    <tr
      v-for="(row, rIndex) in field.properties.basic.cells.data"
      :key="'grid-row' + rIndex"
    >
      <td
        v-for="(cell, cIndex) in row"
        :key="'grid-cell' + rIndex + cIndex"
        class="grid-cell"
        :class="{
          'no-border-left': !cell.properties.basic.border.left,
          'no-border-right': !cell.properties.basic.border.right,
          'no-border-top': !cell.properties.basic.border.top,
          'no-border-bottom': !cell.properties.basic.border.bottom
        }"
      >
        <b-field
          v-if="cell.properties.basic.element && cell.properties.basic.element.show && cell.properties.basic.element.type !== 'hidden'"
          class="column"
          :type="errors[cell.properties.basic.element.id] ? 'is-danger' : 'is-default'"
          :message="errors[cell.properties.basic.element.id] || ''"
        >
          <FieldRenderer
            v-if="cell.properties.basic.element"
            :key="'grid-cell' + rIndex + cIndex"
            :field="cell.properties.basic.element"
            :values="values"
            @update="handleFieldUpdate(cell.properties.basic.element, $event)"
            @error="handleFieldErrors(cell.properties.basic.element, $event, 'error')"
            @success="handleFieldErrors(cell.properties.basic.element, $event, 'success')"
          />
        </b-field>
      </td>
    </tr>
  </table>
</template>
  
<script >
import { defineAsyncComponent } from '@vue/composition-api';
const FieldRenderer = defineAsyncComponent(() => import('./FieldRenderer.vue'));
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const handleFieldUpdate = (cellElement, payload) => {
    emit('update', {
      field: cellElement,
      value: payload?.value
    });
  };
  const handleFieldErrors = (cellElement, message, eventName) => {
    emit(eventName, {
      field: cellElement,
      message
    });
  };
  return {
    handleFieldUpdate,
    handleFieldErrors
  };
};
__sfc_main.components = Object.assign({
  FieldRenderer
}, __sfc_main.components);
export default __sfc_main;
</script>
  
<style lang="scss">
  .n-grid {
    &.no-v-border {
      tr {
        >th, >td {
          &:not(&:first-child), &:not(&:last-child) {
            border-left: none;
            border-right: none;
          }
        }
      }
    }
    &.no-h-border {
      tr {
        >th, >td {
          &:not(&:first-child), &:not(&:last-child) {
            border-top: none;
            border-bottom: none;
          }
        }
      }
    }
    &.no-border {
      tr {
        th, td {
          border: none;
        }
      }
    }
    width: 100%;
    table-layout: fixed;
    .column-row {
      visibility: collapse;
    }
    td {
      &.no-border {
        &-left {
          border-left: none !important;
        }
        &-right {
          border-right: none !important;
        }
        &-top {
          border-top: none !important;
        }
        &-bottom {
          border-bottom: none !important;
        }
      }
    }
    tr {
      .grid-column, .grid-cell {
        padding: 0.25rem 0.5rem;
        border: 1px solid;
        vertical-align: unset;
        &:empty{
          padding: 1rem 2rem;
        }
      }
    }
  }
</style>
  