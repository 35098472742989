var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"n-grid",class:{
    'no-v-border': !_vm.field.properties.basic.hasBorder.vertical,
    'no-h-border': !_vm.field.properties.basic.hasBorder.horizontal,
    'no-border': !_vm.field.properties.basic.hasBorder.all,
  }},[_c('tr',{staticClass:"column-row"},_vm._l((_vm.field.properties.basic.columns.limit),function(_,colIndex){return _c('th',{key:'grid-column' + colIndex,staticClass:"grid-column",style:({
        width: _vm.field.properties.basic.columns.width[colIndex] ? ((_vm.field.properties.basic.columns.width[colIndex]) + "%") : 'auto' 
      })})}),0),_vm._l((_vm.field.properties.basic.cells.data),function(row,rIndex){return _c('tr',{key:'grid-row' + rIndex},_vm._l((row),function(cell,cIndex){return _c('td',{key:'grid-cell' + rIndex + cIndex,staticClass:"grid-cell",class:{
        'no-border-left': !cell.properties.basic.border.left,
        'no-border-right': !cell.properties.basic.border.right,
        'no-border-top': !cell.properties.basic.border.top,
        'no-border-bottom': !cell.properties.basic.border.bottom
      }},[(cell.properties.basic.element && cell.properties.basic.element.show && cell.properties.basic.element.type !== 'hidden')?_c('b-field',{staticClass:"column",attrs:{"type":_vm.errors[cell.properties.basic.element.id] ? 'is-danger' : 'is-default',"message":_vm.errors[cell.properties.basic.element.id] || ''}},[(cell.properties.basic.element)?_c('FieldRenderer',{key:'grid-cell' + rIndex + cIndex,attrs:{"field":cell.properties.basic.element,"values":_vm.values},on:{"update":function($event){return _vm.handleFieldUpdate(cell.properties.basic.element, $event)},"error":function($event){return _vm.handleFieldErrors(cell.properties.basic.element, $event, 'error')},"success":function($event){return _vm.handleFieldErrors(cell.properties.basic.element, $event, 'success')}}}):_vm._e()],1):_vm._e()],1)}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }